<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center container-p-x navbar-height"
  >
    <!-- Brand demo (see demo.css) -->
    <b-navbar-brand
      to="/"
      class="app-brand demo d-lg-none py-0"
    >
      <img
        class="header-img mb-1"
        :src="`${publicUrl}img/logo/aquaservices-blue.png`"
        alt
      />
    </b-navbar-brand>

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse
      is-nav
      id="app-layout-navbar"
    >
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <b-navbar-nav class="align-items-lg-center">
        <!-- Search
        <label class="nav-item navbar-text navbar-search-box p-0 active">
          <i class="ion ion-ios-search navbar-icon align-middle"></i>
          <span class="navbar-search-input pl-2">
            <input v-model="search" type="text" class="form-control navbar-text mx-2" placeholder="Search packs and pallets..." style="width:200px" v-on:keyup.enter="searchAdmin()">
          </span>
        </label> -->

        <!-- Search -->

        <!-- <div class="col-sm-12 col-xl-6">
          <h4 class="font-weight-bold mt-2">
            {{ $t("dashboard.header") }}
            <div class="text-muted text-tiny mt-1">
              <small>Last updated {{ lastUpdate }} </small>
            </div>
          </h4>
        </div> -->
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <!-- <b-badge v-if="isOnline = true && isOffline == false && liveView == true" class="float-right mr-3 mt-2 mb-2"  show variant="success">Online, connected</b-badge> -->

        <!-- <b-button
          class="float-right mr-3 mt-2 mb-2"
          show
          variant="primary"
          @click="newOrder()"
          >Ny bestilling
        </b-button> -->

        <!-- <toggle-button class="float-right mr-3 mt-2 mb-2" v-model="liveView" color="#212a56" :sync="true" :labels="switchLabel" /> -->

        <!-- Divider -->
        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">
          |
        </div>

        <b-nav-item-dropdown
          :right="!isRTL"
          class="demo-navbar-user"
        >
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img :src="`${publicUrl}img/avatars/1.png`" alt class="d-block ui-w-30 rounded-circle"> -->
              <span class="px-1 ml-1 ml-lg-0">{{ user.email }}</span>
            </span>
          </template>

          <b-dd-item @click="goAccount">
            
            <b-row>
                <b-col >
                  <b-avatar v-if="user.company.logo == null"></b-avatar>
                  <b-img class="company-logo" center  v-if="user.company.logo != null" left :src="company.logo.url"></b-img>

                </b-col>
                <br />

                <b-col class="mt-3">
                  <span class="font-weight-heavy">{{ user.username }}</span>
                  <br />

                  <small class="font-weight-normal">{{
                  user.company.name
                }}</small>
                </b-col>
              </b-row>

            <!-- <b-progress :max="10000" height="0.5rem">
              <b-progress-bar :value="packsCount"> </b-progress-bar>
            </b-progress> -->
          </b-dd-item>
          <b-dd-divider />

          <b-dd-item @click="goSettings">{{ $t("menu.settings") }}</b-dd-item>
          <b-dd-item @click="goHelp">{{ $t("menu.help") }}</b-dd-item>
          <b-dd-divider />
          <!-- <b-dd-item @click="logout">Switch user</b-dd-item> -->
          <b-dd-item @click="logout">{{ $t("menu.logout") }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import axios from "axios";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import VueOffline from "vue-offline";

export default {
  name: "app-layout-navbar",
  components: {
    VueBootstrapTypeahead,
    VueOffline,
  },
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      user: {},
      company: [],
    };
  },
  computed: {
    networkStatus() {
      return this.isOnline ? "Network online" : "Network offline";
    },
  },

  methods: {
    getUser() {
      axios.get("https://api.aquaservices.no/users/me").then((res) => {
        this.user = res.data;
        this.company = res.data.company;
      });
    },
    newOrder() {
      this.$router.push({
        path: "/customer/order-new",
      });
    },
    searchEnter() {},
    goAccount: function () {
      this.$router.push("/customer/account");
    },
    goSettings: function () {
      this.$router.push("/customer/settings");
    },
    goHelp: function () {
      this.$router.push("/help");
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
  },
  created: function () {
    this.getUser();
  },
  mounted() {
    return this.$store.getters.user;
  },
};
</script>

<style lang="css">
/* *****************************************************************************
 * Navbar
 */

.navbar-height {
  min-height: 60px;
}

.company-logo {
  max-width: 160px;
}

.header-img-login {
  width: 120px !important;
  padding: 5px;
}

.background-suggestion {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.list-group-item {
  background-color: #ffffff !important;
}

.list-group-item-action {
  background-color: #ffffff !important;
}

.list-group-item-primary {
  background-color: #ffffff !important;
}

.vbst-item {
  background-color: #ffffff !important;
}

.demo-navbar-messages .dropdown-toggle,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user.b-nav-dropdown .nav-link {
  white-space: nowrap;
}

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

@media (min-width: 992px) {
  .demo-navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: 0.5rem;
    width: 22rem;
  }

  .demo-navbar-user .dropdown-menu {
    margin-top: 0.25rem;
  }
}
</style>
