import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '@/store.js'

import globals from '@/globals'

// Layouts
import LayoutCustomer from '@/layout/LayoutCustomer'
import Layout2 from '@/layout/Layout2'
import LayoutMap from '@/layout/LayoutMap'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  routes: [
    {
      path: "/",
      name: "home",
      requiresAuth: true,
      component: () =>
        import(/* webpackChunkName: "about" */ "@/components/Home.vue")
    },
    {
      path: "/suppliers",
      name: "suppliers",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/components/Suppliers.vue")
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/components/admin/Login.vue")
    },
    {
      path: "/register",
      name: "register",
      meta: {
        requiresAuth: false
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "@/components/admin/Register.vue")
    },
    {
      path: '/password-forgot',
      name: "password-forgot",
        component: () => import('@/components/admin/Forgot')
    },
    {
      path: '/password-reset',
      name: "password-reset",
        component: () => import('@/components/admin/Reset')
    },
    {
      path: '/wizard',
      name: "wizard",
        component: () => import('@/components/admin/Wizard')
    },
    {
      path: '/privacy',
      name: "privacy",
      requiresAuth: true,
        component: () => import('@/components/admin/Privacy')
    },
    {
      path: '/support',
      name: "support",
      requiresAuth: true,
        component: () => import('@/components/admin/Support')
    },
   {
    path: '/dashboard',
    component: Layout2,
    meta: {
      requiresAuth: true
    },
    children: [{
      path: 'home',
        component: () => import('@/components/admin/Dashboard')
      },{
      path: 'account',
        component: () => import('@/components/admin/Account')
      },
      {
      path: 'settings',
        component: () => import('@/components/admin/Settings')
      },
      {
        path: 'customers',
        component: () => import('@/components/companies/Customers')
      },  
      {
        path: 'orders',
        component: () => import('@/components/orders/Orders')
      }, 
      {
        path: 'invoice',
        component: () => import('@/components/invoice/Invoice')
      },      
      {
        path: "order-edit/:id",
        name: "order-edit",
        props: true,
        component: () => import('@/components/orders/Order-edit')
      },    
      {
        path: 'worklog',
        component: () => import('@/components/worklog/Worklog')
      },  
      {
        path: 'fls',
        component: () => import('@/components/fls/Fls')
      },  
      {
        path: 'licecount',
        component: () => import('@/components/licecount/Licecount')
      }, 
      {
        path: "licecount-edit/:id",
        name: "licecount-edit",
        props: true,
        component: () => import('@/components/licecount/Licecount-edit')
      },   
      {
        path: 'declarations',
        component: () => import('@/components/declarations/Declarations')
      }, 
      {
        path: 'planner',
        component: () => import('@/components/planner/Planner')
      }, 
      {
        path: 'vessels',
        component: () => import('@/components/vessels/Vessels')
      }, 
      {
        path: 'crew',
        component: () => import('@/components/crew/Crew')
      },
    ]
  },
  {
    path: '/customer',
    component: LayoutCustomer,
    meta: {
      requiresAuth: true
    },
    children: [
      { 
        path: 'home',
        props: true,
        component: () => import('@/components/customer/Customer-home')
      },
      { 
      path: 'account',
      component: () => import('@/components/customer/Customer-account')
    },
      { 
        path: 'settings',
        props: true,
        component: () => import('@/components/customer/Customer-settings')
      },
    { 
      path: 'neworder',
      props: true,
      component: () => import('@/components/customer/Customer-neworder')
    },
    { 
      path: 'orders',
      props: true,
      component: () => import('@/components/customer/Customer-orders')
    },
    {
      path: "order-view/:id",
      name: "order-view",
      props: true,
      component: () => import('@/components/customer/Customer-vieworder')
    }, 
    { 
      path: 'licecount-customer',
      props: true,
      component: () => import('@/components/customer/Customer-licecount')
    },
    {
      path: "licecount-customer-edit/:id",
      name: "licecount-customer-edit",
      props: true,
      component: () => import('@/components/customer/Customer-licecount-edit')
    }, 
    { 
      path: 'map',
      props: true,
      component: () => import('@/components/customer/Customer-map')
    }]
   },
  {
    path: '/tools',
    component: LayoutMap,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'site',
        component: () => import('@/components/tools/Aquaregister')
      },
      {
        path: 'map',
          component: () => import('@/components/tools/Map')
        },
    ]
    },
]
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }

})

export default router
