<template>
  <sidenav
    :orientation="orientation"
    :class="curClasses"
  >
    <!-- Brand demo (see src/demo.css) -->
    <div
      class="app-brand demo"
      v-if="orientation !== 'horizontal'"
    >
      <!-- <span class="app-brand-logo demo">

      </span> -->

      <router-link
        to="/"
        class="navbar-brand line-height-3 py-1"
        style="color: white"
      >

        <img
          class="header-img mb-1"
          :src="`${publicUrl}img/logo/aquaservices-white.png`"
          alt
        />
        <!-- <p>Aquaservices</p> -->
      </router-link>
      <a
        href="javascript:void(0)"
        class="layout-sidenav-toggle sidenav-link text-large ml-auto"
        @click="toggleSidenav()"
      >
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div
      class="sidenav-divider mt-0"
      v-if="orientation !== 'horizontal'"
    ></div>

    <!-- Inner -->

    <div
      class="sidenav-inner"
      :class="{ 'py-1': orientation !== 'horizontal' }"
    >
      <!-- Overview -->

      <sidenav-router-link
        icon="ion ion-ios-speedometer"
        to="/dashboard/home"
        :exact="true"
      >{{ $t("menu.dashboard") }}</sidenav-router-link>

      <sidenav-router-link
        icon="ion ion-ios-map"
        to="/tools/map"
        :exact="true"
      >{{ $t("menu.map") }}</sidenav-router-link>

      
      <sidenav-divider class="mb-1" />


       <!-- Administration menu -->

      <sidenav-menu 
        v-if="user.role.id == '600fe2dc0b7e124b5c5f7f3b'"
        icon="ion ion-md-settings"
        :active="isMenuActive('/dashboards')"
        :open="isMenuOpen('/dashboards')"
      >
      
      
      <template slot="link-text">{{ $t("menu.administration") }}</template>

      <sidenav-router-link
     
        icon="ion ion-ios-briefcase"
        to="/dashboard/customers"
        :exact="true"
      >{{ $t("menu.customers") }}</sidenav-router-link>

       <sidenav-router-link
          icon="ion ion-ios-boat"
          to="/dashboard/vessels"
          :exact="true"
        >{{ $t("menu.vessels") }}</sidenav-router-link>
        <sidenav-router-link
          icon="ion ion-ios-people"
          to="/dashboard/crew"
          :exact="true"
        >{{ $t("menu.personell") }}</sidenav-router-link>
     

        <ion-icon name="caret-forward-circle-outline"></ion-icon>
      </sidenav-menu>

      <!-- Orders -->


      <sidenav-menu
        icon="ion ion-md-list"
        :active="isMenuActive('/dashboards')"
        :open="isMenuOpen('/dashboards')"
      >
        <template slot="link-text">{{ $t("menu.orders") }}</template>

      <sidenav-router-link
        icon="ion ion-ios-list"
        to="/dashboard/orders"
        :exact="true"
      >{{ $t("menu.orderlist") }}</sidenav-router-link>

      <sidenav-router-link
       v-if="user.role.id == '600fe2dc0b7e124b5c5f7f3b'"
        icon="ion ion-ios-checkbox"
        to="/dashboard/planner"
        :exact="true"
      >{{ $t("menu.planner") }}</sidenav-router-link>

      <sidenav-router-link
        icon="ion ion-ios-clipboard"
        to="/dashboard/worklog"
        :exact="true"
      >{{ $t("menu.worklog") }}</sidenav-router-link>
     
      <sidenav-router-link
        icon="ion ion-ios-attach"
        to="/dashboard/declarations"
        :exact="true"
      >{{ $t("menu.declarations") }}</sidenav-router-link>

      <sidenav-router-link
       v-if="user.role.id == '600fe2dc0b7e124b5c5f7f3b'"
        icon="ion ion-ios-download"
        to="/dashboard/invoice"
        :exact="true"
      >{{ $t("menu.invoice") }}</sidenav-router-link>


        <ion-icon name="caret-forward-circle-outline"></ion-icon>
      </sidenav-menu>

      <!-- Delice menu -->

      <sidenav-menu
        icon="ion ion-md-bug"
        :active="isMenuActive('/dashboards')"
        :open="isMenuOpen('/dashboards')"
      >
        <template slot="link-text">{{ $t("menu.delice") }}</template>

       <sidenav-router-link
        icon="ion ion-ios-bug"
        to="/dashboard/licecount"
        :exact="true"
      >{{ $t("menu.licecount") }}</sidenav-router-link>

      <sidenav-router-link
        icon="ion ion-ios-funnel"
        to="/dashboard/fls"
        :exact="true"
      >{{ $t("menu.fls") }}</sidenav-router-link>
     

        <ion-icon name="caret-forward-circle-outline"></ion-icon>
      </sidenav-menu>

      <!-- Tools -->

      <sidenav-divider class="mb-1" />

      <sidenav-menu
        icon="ion ion-md-hammer"
        :active="isMenuActive('/dashboards')"
        :open="isMenuOpen('/dashboards')"
      >
        <template slot="link-text">{{ $t("menu.tools") }}</template>
        <sidenav-router-link
          icon="ion ion-ios-list"
          to="/tools/site"
          :exact="true"
        >{{ $t("menu.site") }}</sidenav-router-link>

        <!-- <sidenav-router-link
          icon="ion ion-ios-map"
          to="/tools/map"
          :exact="true"
        >{{ $t("menu.map") }}</sidenav-router-link> -->
        <!-- <sidenav-router-link icon="ion ion-ios-albums" to="/dashboard/batches" :exact="true">Batches</sidenav-router-link> -->
        <!-- <sidenav-router-link icon="ion ion-ios-qr-scanner" to="/dashboard/scanner" :exact="true">Scanner</sidenav-router-link> -->
        <!-- <sidenav-router-link icon="ion ion-ios-link" to="/dashboard/blockchain" :exact="true">Blockchain</sidenav-router-link> -->

        <ion-icon name="caret-forward-circle-outline"></ion-icon>
      </sidenav-menu>
    </div>

    <nav class="footer">
      <hr />
      <div class="container-fluid">
        <div>
          <div class="footer-text font-weight-bold mb-2">Trenger du hjelp?</div>
          <a
            class="footer-text footer-link small mb-3"
            href="mailto:support@aquaservices.no"
          >support@aquaservices.no</a>
          <br />
        </div>
      </div>
    </nav>
  </sidenav>
</template>

<script>
import axios from "axios";

import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    },
  },
  data() {
    return {
        user: {
          role: "602b924e1b415e433893ba3b"
        },
      }
  },
  methods: {
    getUser() {
        axios.get("https://api.aquaservices.no/users/me").then((res) => {
          this.user = res.data;
          this.company = res.data.company;
        });
    },
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
  },
  beforeMount() {
      this.getUser();
  },
  //created: function () {
  //    this.getUser()
  //},
};
</script>


<style lang="css">
.header-img {
  width: 165px !important;
}

.default-style .sidenav .app-brand.demo {
  height: 66px;
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.app-brand-logo.demo {
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sidenav-link {
  font-size: 15px !important;
}

.app-brand-logo.demo svg {
  width: 20px;
  height: 11px;
}

.app-brand-text.demo {
  font-size: 1.2rem;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -ms-flexbox !important;
  display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
  display: block !important;
}

@media (min-width: 992px) {
  .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
  .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
    display: none;
  }
}

/* Links */

.footer-link {
  color: #ffffff;
}

/* unvisited link */
.footer-link:link {
  color: #ffffff !important;
}

/* visited link */
.footer-link:visited {
  color: #ffffff !important;
}

/* mouse over link */
.footer-link:hover {
  color: #ffffff !important;
}

/* selected link */
.footer-link:active {
  color: #ffffff !important;
}
</style>