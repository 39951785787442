<template>
  <div>

    <b-navbar
      toggleable="lg"
      :variant="getLayoutNavbarBg()"
      class="layout-navbar align-items-lg-center container-p-x navbar-height"
    >
    <!-- Brand demo (see demo.css) -->
    <b-navbar-brand
      to="/"
      class="app-brand demo d-lg-none py-0"
    >
      <img
        class="header-img mb-1"
        :src="`${publicUrl}img/logo/aquaservices-blue.png`"
        alt
      />
    </b-navbar-brand>

      <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

      <b-collapse
        is-nav
        id="app-layout-navbar"
      >
        <!-- Divider -->
        <hr class="d-lg-none w-100 my-2" />

        <b-badge
          pill
          v-if="notification != null"
          variant="primary"
        >{{
        notification_type
      }}</b-badge>

        <b-badge
          class="ml-1"
          pill
          v-if="notification != null"
          variant="light"
        >{{
        notification
      }}</b-badge>

        <b-navbar-nav class="align-items-lg-center">
          <!-- <label class="nav-item navbar-text navbar-search-box p-0 active">
          <i class="ion ion-ios-search navbar-icon align-middle"></i>
          <span class="navbar-search-input pl-2">
            <input
              v-model="search"
              type="text"
              class="form-control navbar-text mx-2"
              placeholder="Søk.."
              style="width: 200px"
              v-on:keyup.enter="searchAdmin()"
            />
          </span>
        </label> -->

          <!-- Search -->

          <!-- <div class="col-sm-12 col-xl-6">
          <h4 class="font-weight-bold mt-2">
            {{ $t("dashboard.header") }}
            <div class="text-muted text-tiny mt-1">
              <small>Last updated {{ lastUpdate }} </small>
            </div>
          </h4>
        </div> -->
        </b-navbar-nav>

        <b-navbar-nav class="align-items-lg-center ml-auto">
          <!-- <b-badge v-if="isOnline = true && isOffline == false && liveView == true" class="float-right mr-3 mt-2 mb-2"  show variant="success">Online, connected</b-badge> -->


          <b-navbar-nav class="d-lg-none">
            <b-nav-item href="/#/dashboard/home">Oversikt</b-nav-item>
            <b-nav-item href="/#/dashboard/orders">Ordrer</b-nav-item>
            <b-nav-item href="/#/dashboard/licecount">Lusetelling</b-nav-item>
          </b-navbar-nav>

          <b-badge
            v-if="(isOnline = true && isOffline == false)"
            class="float-right mr-2 mt-2 mb-2 d-none d-lg-block"
            show
            variant="success"
          >
            Online</b-badge>
          <b-badge
            v-if="isOffline == true"
            class="float-right mr-2 mt-2 d-none d-lg-block"
            show
            variant="danger"
          >Offline</b-badge>

          <!-- <b-badge
          v-if="(connected = true)"
          class="float-right mr-2 mt-2 mb-2"
          show
          variant="success"
        >
          Tilkoblet</b-badge
        >
        <b-badge
          v-if="connected == false"
          class="float-right mr-2 mt-2"
          show
          variant="danger"
          >Tilkoblet</b-badge
        > -->

          <!-- <toggle-button class="float-right mr-3 mt-2 mb-2" v-model="liveView" color="#212a56" :sync="true" :labels="switchLabel" /> -->

          <!-- Divider -->
          <div class="nav-item d-none d-lg-block tex1t-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">
            |
          </div>

          <b-nav-item-dropdown
            :right="!isRTL"
            class="demo-navbar-user"
          >
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <!-- <img :src="`${publicUrl}img/avatars/1.png`" alt class="d-block ui-w-30 rounded-circle"> -->
                <span class="px-1 ml-1 ml-lg-0">{{ user.email }}</span>
              </span>
            </template>

            <b-dd-item @click="goAccount">

              
              <b-row>
                <b-col >
                  <b-avatar v-if="user.company.logo == null"></b-avatar>
                  <b-img class="company-logo" center  v-if="user.company.logo != null" left :src="company.logo.url"></b-img>

                </b-col>
                <br />

                <b-col class="mt-3">
                  <span class="font-weight-heavy">{{ user.username }}</span>
                  <br />

                  <small class="font-weight-normal">{{
                  user.company.name
                }}</small>
                </b-col>
              </b-row>

              <!-- <b-progress :max="10000" height="0.5rem">
              <b-progress-bar :value="packsCount"> </b-progress-bar>
            </b-progress> -->
            </b-dd-item>
            <b-dd-divider />

            <b-dd-item
              v-if="user.role.name == 'Administrator'"
              @click="goSettings"
            >{{ $t("menu.settings") }}</b-dd-item>
            <b-dd-item @click="goHelp">{{ $t("menu.help") }}</b-dd-item>
            <b-dd-divider />
            <!-- <b-dd-item @click="logout">Switch user</b-dd-item> -->
            <b-dd-item @click="openChangeAccount()">{{ $t("menu.changeaccount") }}</b-dd-item>
            <b-dd-item @click="logout">{{ $t("menu.logout") }}</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-modal
      id="changeAccountModal"
      title="Bytt konto"
    >
      <!-- Form -->
      <form class="">
        <b-form-group :label="$t('login.email')">
          <b-input
            type="email"
            v-on:keyup.enter="login"
            v-model="identifier"
          />
        </b-form-group>
        <b-form-group>
          <div
            slot="label"
            class="d-flex justify-content-between align-items-end"
          >
            <div>{{ $t("login.password") }}</div>
          </div>
          <b-input
            type="password"
            v-on:keyup.enter="login"
            v-model="password"
          />

        </b-form-group>

          <b-alert
            class="font-weight-normal"
            v-model="showPasswordAlert"
            variant="danger"
            dismissible
          >
            Feil brukernavn eller passord, vennligst prøv igjen.
          </b-alert>

      </form>
      <!-- / Form -->

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="changeAccount()"
          >
            Bytt konto
          </b-button>

          <b-button
            variant="default"
            class="float-right mr-2"
            @click="closeChangeAccount()"
          >
            Avbryt
          </b-button>
        </div>
      </template>

    </b-modal>

  </div>

</template>

<script>
import axios from "axios";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import VueOffline from "vue-offline";
import VueMoment from "vue-moment";
import moment from "moment";
import io from "socket.io-client";

const socket = io("https://api.aquaservices.no", {
  transports: ["websocket"],
  upgrade: false,
});

export default {
  name: "app-layout-navbar",
  components: {
    VueBootstrapTypeahead,
    VueOffline,
  },
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      user: {},
      company: [],
      identifier: "",
      password: "",
      showPasswordAlert: false,
      showErrorAlert: false,
      notification: null,
      notification_type: null,
      connected: false,
      search: "",
    };
  },
  computed: {
    networkStatus() {
      return this.isOnline ? "Network online" : "Network offline";
    },
  },

  methods: {
    getUser() {
      axios.get("https://api.aquaservices.no/users/me").then((res) => {
        this.user = res.data;
        this.company = res.data.company;
      });
    },
    socketConnect() {
      // Connect to websocket
      socket.on("connect", function () {
        console.log("User is connected");
        this.connected = true;
      });
    },
    searchDashboard() {
      this.$router.push({
        path: "/dashboard/search",
        query: {
          sscc: this.search,
        },
      });
    },
    newOrder() {
      this.$router.push({
        path: "/customers/customer-order",
        params: { supplier_company: this.user.company.id },
        query: {
          supplier_company: this.user.company.id,
        },
      });
    },
    changeAccount: function () {
      this.$store.dispatch("logout").then(() => {
        this.login();
      });
    },
    login: function () {
      this.isLoading = true;
      let identifier = this.identifier;
      let password = this.password;
      this.$store
        .dispatch("login", {
          identifier,
          password,
        })
        .then((response) => {
          if (response.data.user.wizard == false) {
            this.$router.push("/wizard");
          }

          if (response.data.user.company.type == "Supplier") {
            this.$router.push("/");
          }

          if (response.data.user.company.type == "Customer") {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
            this.showPasswordAlert = true;
            this.isLoading = false;
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
            this.showErrorAlert = true;
            this.isLoading = false;
          } else {
            // Something happened in setting up the request that triggered an Error
            this.showErrorAlert = true;
            this.isLoading = false;
          }

          this.isLoading = false;
        });
    },
    openChangeAccount() {
      this.showPasswordAlert = false
      this.showErrorAlert = false;
      this.$bvModal.show("changeAccountModal");
      // this.$refs["changeAccountModal"].show();
    },
    closeChangeAccount() {
      this.showPasswordAlert = false
      this.showErrorAlert = false;
      this.$bvModal.hide("changeAccountModal");
    },
    searchEnter() {
      console.log("Search");
    },
    goAccount: function () {
      this.$router.push("/dashboard/account");
    },
    goSettings: function () {
      this.$router.push("/dashboard/settings");
    },
    goHelp: function () {
      this.$router.push("/help");
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
  },
  created: function () {
    this.getUser();
    this.socketConnect();
  },
  mounted() {
    // socket.on("message", (data) => {
    //  this.notification_type = "Melding";
    //  this.notification =
    //    moment().format("HH:mm") + " - Melding fra " + data.from;
    //});
    //socket.on("fls", (data) => {
    //  this.notification_type = "FLS";
    //  this.notification =
    //    moment().format("HH:mm") +
    //    " -  " +
    //    data.ordernumber +
    //    " - " +
    //    data.customer +
    //    " (" +
    //    data.site +
    //    ")";
    //});
    //socket.on("logs", (data) => {
    //  this.notification =
    //    moment().format("HH:mm") + " - Logg: " + JSON.stringify(data);
    //});
    //socket.on("orders", (data) => {
    //  console.log(data);
    //  this.notification_type = "Ordre";
    //  this.notification =
    //    moment().format("HH:mm") +
    //    " -  " +
    //    data.ordernumber +
    //    " (" +
    //    data.site.number +
    //    " - " +
    //    data.site.name +
    //   ")";
    //});
  },
};
</script>

<style lang="css">
/* *****************************************************************************
 * Navbar
 */

.navbar-height {
  min-height: 60px;
}

.company-logo {
  max-width: 160px;
}

.header-img-login {
  width: 120px !important;
  padding: 5px;
}

.background-suggestion {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.list-group-item {
  background-color: #ffffff !important;
}

.list-group-item-action {
  background-color: #ffffff !important;
}

.list-group-item-primary {
  background-color: #ffffff !important;
}

.vbst-item {
  background-color: #ffffff !important;
}

.demo-navbar-messages .dropdown-toggle,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user.b-nav-dropdown .nav-link {
  white-space: nowrap;
}

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

@media (min-width: 992px) {
  .demo-navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: 0.5rem;
    width: 22rem;
  }

  .demo-navbar-user .dropdown-menu {
    margin-top: 0.25rem;
  }
}
</style>
