<template>
  <sidenav
    :orientation="orientation"
    :class="curClasses"
  >
    <!-- Brand demo (see src/demo.css) -->
    <div
      class="app-brand demo"
      v-if="orientation !== 'horizontal'"
    >
      <!-- <span class="app-brand-logo demo">

      </span> -->

      <router-link
        to="/"
        class="navbar-brand line-height-3 py-1"
        style="color: white"
      >

        <img
          class="header-img mb-1"
          :src="`${publicUrl}img/logo/aquaservices-white.png`"
          alt
        />
        <!-- <p>Aquaservices</p> -->
      </router-link>
      <a
        href="javascript:void(0)"
        class="layout-sidenav-toggle sidenav-link text-large ml-auto"
        @click="toggleSidenav()"
      >
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div
      class="sidenav-divider mt-0"
      v-if="orientation !== 'horizontal'"
    ></div>

    <!-- Inner -->

    <div
      class="sidenav-inner"
      :class="{ 'py-1': orientation !== 'horizontal' }"
    >
      <!-- Dashboard -->

      <sidenav-router-link
        icon="ion ion-ios-add"
        to="/customer/neworder"
        :exact="true"
      >{{ $t("menu.neworder") }}</sidenav-router-link>

      <!-- Orders -->

      <sidenav-divider class="mb-1" />
      <!-- <li class="sidenav-header small font-weight-semibold">Ordrer</li> -->

      
      <sidenav-router-link
        icon="ion ion-ios-speedometer"
        to="/customer/home"
        :exact="true"
      >{{ $t("menu.dashboard") }}</sidenav-router-link>

      <sidenav-router-link
        icon="ion ion-ios-list"
        to="/customer/orders"
        :exact="true"
      >{{ $t("menu.orders") }}</sidenav-router-link>

      <sidenav-router-link
        icon="ion ion-ios-bug"
        to="/customer/licecount-customer"
        :exact="true"
      >{{ $t("menu.licecount") }}</sidenav-router-link>

      <!-- Tools -->

      <!-- <sidenav-divider class="mb-1" />
      <li class="sidenav-header small font-weight-semibold">Verktøy</li>

      <sidenav-router-link
        icon="ion ion-ios-albums"
        to="/customer/site"
        :exact="true"
        >{{ $t("menu.site") }}</sidenav-router-link
      >

      <sidenav-router-link
        icon="ion ion-ios-map"
        to="/customer/map"
        :exact="true"
        >{{ $t("menu.map") }}</sidenav-router-link
      > -->

      <!-- <sidenav-menu
        icon="ion ion-md-hammer"
        :active="isMenuActive('/customer')"
        :open="isMenuOpen('/customer')"
      >
        <template slot="link-text">{{ $t("menu.tools") }}</template>


        <ion-icon name="caret-forward-circle-outline"></ion-icon>
      </sidenav-menu> -->
    </div>

    <nav class="footer">
      <hr />
      <div class="container-fluid">
        <div>
          <div class="footer-text font-weight-bold mb-2">Trenger du hjelp?</div>
          <a
            class="footer-text footer-link small mb-3"
            href="mailto:support@aquaservices.no"
          >support@aquaservices.no</a>
          <br />
        </div>
      </div>
    </nav>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    },
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
  },
};
</script>


<style lang="css">
.header-img {
  width: 150px !important;
}

.default-style .sidenav .app-brand.demo {
  height: 66px;
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.app-brand-logo.demo {
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sidenav-link {
  font-size: 15px !important;
}

.app-brand-logo.demo svg {
  width: 20px;
  height: 11px;
}

.app-brand-text.demo {
  font-size: 1.2rem;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -ms-flexbox !important;
  display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
  display: block !important;
}

@media (min-width: 992px) {
  .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
  .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
    display: none;
  }
}

/* Links */

.footer-link {
  color: #ffffff;
}

/* unvisited link */
.footer-link:link {
  color: #ffffff !important;
}

/* visited link */
.footer-link:visited {
  color: #ffffff !important;
}

/* mouse over link */
.footer-link:hover {
  color: #ffffff !important;
}

/* selected link */
.footer-link:active {
  color: #ffffff !important;
}
</style>