import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import AsyncComputed from 'vue-async-computed'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'
import './registerServiceWorker'

import i18n from './i18n'
import Axios from 'axios'
import VueGeolocation from 'vue-browser-geolocation'
import Vue2Filters from 'vue2-filters'
import VueCarousel from 'vue-carousel'
import wysiwyg from 'vue-wysiwyg'
import VueQriously from 'vue-qriously'
import VueOffline from 'vue-offline'
import VueNumerals from 'vue-numerals'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import VueClipboard from 'vue-clipboard2'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueJsonPretty from 'vue-json-pretty';
import VueDayMomentJS from 'vue-day-moment-js'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Dayjs from 'vue-dayjs';
import VueDayjs from 'vue-dayjs-plugin'
import VueHtml2pdf from 'vue-html2pdf'
import VCalendar from 'v-calendar';
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import vSelect from 'vue-select'


// Import CSS files
import 'leaflet/dist/leaflet.css'
import 'vue-json-pretty/lib/styles.css';
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import 'vue-select/dist/vue-select.css';

// Import BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(AsyncComputed)
Vue.use(BootstrapVue)
Vue.use(Axios)
Vue.use(VueGeolocation),
Vue.use(Vue2Filters)
Vue.use(VueCarousel)
Vue.use(wysiwyg, {})
Vue.use(VueJsonPretty)
Vue.use(VueQriously)
Vue.use(VueOffline)
Vue.use(Chartkick.use(Chart))
Vue.use(VueClipboard)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VueBootstrapTypeahead)
Vue.use(VueHtml2pdf)
Vue.use(VCalendar)
Vue.use(vueFilterPrettyBytes)
Vue.component('v-select', vSelect)
Vue.use(Dayjs, {
  filters: {
    ago: 'ago',
  },
});
Vue.use(VueDayjs)

Vue.use(VueNumerals, {
  locale: 'no'
});

Vue.prototype.$http = Axios;

const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + token
}

// Global RTL flag
Vue.mixin({
  data: globals
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
