<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
export default {
  name: "app",
  metaInfo: {
    title: "Aquaservices",
    titleTemplate: "%s - Aquaservices",
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userEmail: function () {
      return this.$store.getters.userEmail;
    },
  },
  methods: {
    updated() {
      // Remove loading state
      setTimeout(() => document.body.classList.remove("app-loading"), 1);
    },
    getGeolocation() {
      this.$getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0, //defaults to 0
      }).then((coordinates) => {
        this.geolocation = coordinates;
      });
    },
  },
  created: function () {
    this.updated();
  },
};
</script>

<style lang="css">
.body {
  background: #fdfdfd;
}
</style>
